// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_URL = "https://apirecette.jevebara.com";

// const IP_API = BASE_URL+"api/";

// const BASE_URL = "http://88.198.209.187:53596";

// http://88.198.209.187:53596/api/login

// const BASE_URL = "http://192.168.8.101:8000/";

const IP_API = BASE_URL+"/";


export const environment = {
  production: false,
  //apiUrl: IP_API+":3627",
  baseUrl : BASE_URL,
  apiUrl: IP_API
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
